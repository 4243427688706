import React from 'react';
import PropTypes from 'prop-types';

export function getCoverFromBank(isbn) {
  return `/covers/elt/medium/${isbn}_medium.jpg`;
}

export function getDefaultCoverImage() {
  return '/static/images/offline/default-cover.gif';
}

const onCoverImageError = event => {
  event.target.src = getDefaultCoverImage();
};

function OfflineCover({ isbn, name, img }) {
  return <img src={img || getCoverFromBank(isbn)} alt={name} onError={onCoverImageError} />;
}

OfflineCover.propTypes = {
  isbn: PropTypes.string.isRequired,
  name: PropTypes.string,
  img: PropTypes.string
};

export default OfflineCover;
