import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UnitItem from './UnitItem';
// Styles
import styles from './UnitList.scss';

function UnitList({ units = [], downloadButton = false, downloadCallback, userId }) {
  const [downloadUnitInProgress, setDownloadUnitInProgress] = useState(null);

  return (
    <div className={styles.unitsContainer}>
      {units.map(unit => (
        <div
          className={classNames(styles.unitWrapper, {
            [styles.hasPrintView]: unit.hasAnyActivityWithPrintView && !unit.userId?.includes(userId)
          })}
          key={unit.uid}
        >
          <UnitItem
            unit={unit}
            downloadButton={downloadButton}
            downloadCallback={downloadCallback}
            userId={userId}
            setDownloadUnitInProgress={setDownloadUnitInProgress}
            downloadUnitInProgress={downloadUnitInProgress}
          />
        </div>
      ))}
    </div>
  );
}

UnitList.propTypes = {
  units: PropTypes.array.isRequired,
  downloadButton: PropTypes.bool,
  downloadCallback: PropTypes.func,
  userId: PropTypes.string
};

export default UnitList;
