import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Checkbox from '@oup/shared-front-end/src/components/Checkbox/Checkbox.js';
import withLocalizedContent from '../../language/withLocalizedContent.js';
import styles from './UnitList.scss';
import ProgressiveButton, {
  STATUS_STARTED,
  STATUS_ENDED,
  STATUS_NOT_STARTED,
  STATUS_FAILED
} from '../ProgressiveButton/ProgressiveButton';

function UnitItem({
  unit = {},
  downloadButton = false,
  downloadCallback,
  userId: loggedUser,
  offlineDownloadProgress,
  downloadUnitInProgress,
  setDownloadUnitInProgress,
  localizedContent: { productDownload: offlineContent }
}) {
  let initialStatus = STATUS_NOT_STARTED;
  if (unit.userId && unit.userId.length > 0) {
    initialStatus = unit.userId.includes(loggedUser) ? STATUS_ENDED : STATUS_NOT_STARTED;
  }
  const [unitStatus, setUnitStatus] = useState(initialStatus);
  const [percent, setPercent] = useState(0);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [lastEffectTime, setLastEffectTime] = useState(Date.now());
  const [isDelayed, setIsDelayed] = useState(false);
  const [isMonitoring, setIsMonitoring] = useState(false);
  const [isPrintViewSelected, setIsPrintViewSelected] = useState(true);

  const useDebugEffect = (effect, deps, timeoutMs = 15000) => {
    useEffect(() => {
      if (!isMonitoring) return;

      const startTime = Date.now();
      setLastEffectTime(startTime);
      setIsDelayed(false);

      const timeoutId = setTimeout(() => {
        const currentTime = Date.now();
        if (currentTime - startTime > timeoutMs) {
          setIsDelayed(true);
        }
      }, timeoutMs);

      const cleanup = effect();

      // eslint-disable-next-line consistent-return
      return () => {
        if (cleanup) cleanup();
        clearTimeout(timeoutId);
      };
    }, [isMonitoring, ...deps]);
  };

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useDebugEffect(() => {
    if (isDelayed) {
      setUnitStatus(STATUS_FAILED);
      setDownloadUnitInProgress(null);
      setIsMonitoring(false);
      setIsDelayed(false);
      return;
    }
    if (offlineDownloadProgress[unit?.uId] && Object.keys(offlineDownloadProgress[unit?.uId]).length > 0) {
      if (unit?.activities?.length > 0) {
        const successfulDownloadsCount = Object.values(offlineDownloadProgress[unit?.uId]).filter(
          status => status === 'success'
        ).length;
        const failureDownloadsCount = Object.values(offlineDownloadProgress[unit?.uId]).filter(
          status => status === 'failure'
        ).length;

        if (!isOnline) {
          setUnitStatus(STATUS_FAILED);
          setDownloadUnitInProgress(null);
          setIsMonitoring(false);
          setIsDelayed(false);
        } else if (successfulDownloadsCount === unit.activities?.length) {
          setUnitStatus(STATUS_ENDED);
          setDownloadUnitInProgress(null);
          setIsMonitoring(false);
          setIsDelayed(false);
        } else if (failureDownloadsCount === unit.activities?.length) {
          setUnitStatus(STATUS_FAILED);
          setDownloadUnitInProgress(null);
          setIsMonitoring(false);
          setIsDelayed(false);
        } else {
          setUnitStatus(STATUS_STARTED);
          setPercent(Math.round((successfulDownloadsCount / unit.activities.length) * 100));
        }
      }
    }
  }, [JSON.stringify(offlineDownloadProgress[unit?.uId]), isDelayed, lastEffectTime]);

  return (
    <>
      <div className={styles.unitItem}>
        <div className={styles.unitNameColumn}>
          <div className={styles.unitName}>{unit.levelName}</div>
        </div>
        {unit.size && (
          <div className={styles.sizeColumn}>
            <div className={styles.size}>{unit.size}</div>
          </div>
        )}
        <div className={styles.actionsColumn}>
          {downloadButton && (
            <ProgressiveButton
              initialStatus={unitStatus}
              percent={percent}
              isDisabled={downloadUnitInProgress && downloadUnitInProgress !== unit?.uId}
              startCallback={async () => {
                const isSuccess = await downloadCallback(unit.uId, isPrintViewSelected);
                if (isSuccess) {
                  setDownloadUnitInProgress(unit.uId);
                } else {
                  setDownloadUnitInProgress(null);
                }
                setIsDelayed(false);
                setIsMonitoring(true);
                setLastEffectTime(Date.now());
                return isSuccess;
              }}
            />
          )}
        </div>
      </div>
      {unit.hasAnyActivityWithPrintView && !unit.userId?.includes(loggedUser) && (
        <div className={styles.printViewCheckboxContainer}>
          <div className={styles.printViewCheckboxSubContainer}>
            <Checkbox
              key={`${unit.uid}-checkbox`}
              label={offlineContent.include_print_pages}
              value={isPrintViewSelected}
              checked={isPrintViewSelected}
              onChange={() => setIsPrintViewSelected(!isPrintViewSelected)}
            />
          </div>
        </div>
      )}
    </>
  );
}

UnitItem.propTypes = {
  unit: PropTypes.object,
  downloadButton: PropTypes.bool,
  downloadCallback: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  userId: PropTypes.string,
  offlineDownloadProgress: PropTypes.object,
  downloadUnitInProgress: PropTypes.string,
  setDownloadUnitInProgress: PropTypes.func
};

export default compose(
  withLocalizedContent('productDownload'),
  connect(
    ({ offlineContentPlayer }) => ({
      offlineDownloadProgress: offlineContentPlayer.offlineDownloadProgress
    }),
    {}
  )
)(UnitItem);
